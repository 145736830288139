import { CommodityTypes } from "./DataTypes";
import ImgSmartphone from "../../public/static/images/svg/komoditi-smartphone.svg";
import ImgPCLaptop from "../../public/static/images/svg/komoditi-laptopkomputer.svg";
import ImgCamera from "../../public/static/images/svg/komoditi-kamera.svg";
import ImgTelevisi from "../../public/static/images/svg/komoditi-televisi.svg";
import ImgFurniture from "../../public/static/images/svg/komoditi-furnitur.svg";
import ImgGadget from "../../public/static/images/svg/komoditi-gadget.svg";

export const Data = [
  {
    name: "Handphone",
    img: ImgSmartphone,
    type: CommodityTypes.handphone,
    data: {
      insurance: false,
      minPrice: 825000,
      maxPrice: 14250000,
      specialPrice: 5600000,
      ir: 0.0249,
      tenor: [6, 9],
      minTenor: 6,
      maxTenor: 9,
      minDP: 0.2,
      maxDP: 0.3,
      minAdminFee: 99000,
      maxAdminFee: 199000
    }
  },
  {
    name: "Gadget",
    img: ImgGadget,
    type: CommodityTypes.gadget,
    data: {
      insurance: false,
      minPrice: 825000,
      maxPrice: 14250000,
      specialPrice: 5600000,
      ir: 0.0249,
      tenor: [6, 9],
      minTenor: 6,
      maxTenor: 9,
      minDP: 0.2,
      maxDP: 0.3,
      minAdminFee: 99000,
      maxAdminFee: 199000
    }
  },
  {
    name: "Furnitur",
    img: ImgFurniture,
    type: CommodityTypes.furniture,
    data: {
      insurance: true,
      minPrice: 1000000,
      maxPrice: 11000000,
      ir: 0.0199,
      specialPrice: 5000000,
      tenor: [6, 9],
      minTenor: 6,
      maxTenor: 9,
      minDP: 0.05,
      maxDP: 0.1,
      minAdminFee: 199000,
      maxAdminFee: 199000
    }
  },
  {
    name: "Kamera",
    img: ImgCamera,
    type: CommodityTypes.kamera,
    data: {
      insurance: false,
      minPrice: 1200000,
      maxPrice: 12500000,
      ir: 0.0199,
      specialPrice: null,
      tenor: [6, 9],
      minTenor: 6,
      maxTenor: 9,
      minDP: 0.2,
      maxDP: 0.2,
      minAdminFee: 199000,
      maxAdminFee: 199000
    }
  },
  {
    name: "Televisi",
    img: ImgTelevisi,
    type: CommodityTypes.televisi,
    data: {
      insurance: false,
      minPrice: 1200000,
      maxPrice: 12500000,
      specialPrice: null,
      ir: 0.0229,
      tenor: [6, 9],
      minTenor: 6,
      maxTenor: 9,
      minDP: 0.2,
      maxDP: 0.2,
      minAdminFee: 199000,
      maxAdminFee: 199000
    }
  },
  {
    name: "Komputer atau Laptop",
    img: ImgPCLaptop,
    type: CommodityTypes.komputer_atau_laptop,
    data: {
      insurance: false,
      minPrice: 1200000,
      maxPrice: 12500000,
      specialPrice: null,
      ir: 0.0229,
      tenor: [6, 9],
      minTenor: 6,
      maxTenor: 9,
      minDP: 0.2,
      maxDP: 0.2,
      minAdminFee: 199000,
      maxAdminFee: 199000
    }
  }
];
